import React from 'react'
import MaterialList from '../MaterialList/MaterialList'

function Materiales() {
  return (
    <div>
      <MaterialList />
    </div>
  ) 
}

export default Materiales
