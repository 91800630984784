import React, { useEffect } from "react";
import { app } from "../../fb";
import Users from "./User";
import Logueo from "./Login";

 
function CLase() {
   

  return <div style={{marginTop : "100px"  }} > <Users/></div>
  
}

export default CLase
