import firebase from "firebase/compat/app";
import "firebase/compat/auth";

export const app = firebase.initializeApp({
  apiKey: "AIzaSyBf1-hdEfkIOVSbg0rpvI2korXBUSE2oOU",
  authDomain: "mireya-a19d1.firebaseapp.com",
  projectId: "mireya-a19d1",
  storageBucket: "mireya-a19d1.appspot.com",
  messagingSenderId: "1032466608525",
  appId: "1:1032466608525:web:d4c4e435e3c31d98e18dc4",
  measurementId: "G-BPVQN5RNX7"
}); 